const Products = require('../products/products');

const addons = [
    {
        name: 'AnchorPay',
        description: 'AnchorPay is a robust payment platform woven directly into our software. With our payment processing solution, you can contact the same team of experts at SpeedyDock for both payments and software support. Reconcile payments easily, and never worry about calling third-party merchant processing again. *AnchorPay does not provide payment services. Payment services are provided by Fullsteam Operations LLC in the United States and FS Payments Canada Ltd. in Canada.',
        isComingSoon: false,
        isNew: false,
        products: [Products.DRY_STACK.name, Products.BOAT_CLUB.name, Products.BOAT_RENTAL.name],
        urlSlug: 'anchor-pay',
    },
    {
        name: 'Make-Up Charters',
        description: 'Sell tickets or spots for charters or events. Availability is easily managed through our rental booking engine.',
        isComingSoon: false,
        isNew: false,
        //photoSrc: '/addons/live-stream.jpg',
        products: [Products.BOAT_RENTAL.name, Products.BOAT_CLUB.name],
        urlSlug: 'make-up-charters',
    },
    {
        name: 'Lessons Module',
        description: 'Assign classes, students, and boats easily with just a few clicks. Bill students for classes, books & materials and incidental costs. Integrates with boat club and rental fleet availability.',
        isComingSoon: false,
        isNew: false,
        //photoSrc: '/addons/live-stream.jpg',
        products: [Products.BOAT_RENTAL.name, Products.BOAT_CLUB.name],
        urlSlug: 'lessons-module',
    },
    {
        name: 'Custom Digital Signage',
        description: 'Custom displays can be built with information from SpeedyDock to display to customers or for viewing from the office. Show customers their place in line upon arrival or show useful stats for marina staff.',
        isComingSoon: false,
        isNew: false,
        photoSrc: '/addons/digital-signage.jpg',
        products: [Products.DRY_STACK.name],
        urlSlug: 'digital-signage',
    },
    {
        name: 'Live Stream Video',
        description: 'Marina camera live stream available for display in-app for customers to view or for embedding on a website.',
        isComingSoon: false,
        isNew: false,
        photoSrc: '/addons/live-stream.jpg',
        products: [Products.DRY_STACK.name],
        urlSlug: 'live-stream',
    },
    {
        name: 'Customer Check-In Kiosk',
        description: 'Customers can check-in at the marina by either scanning a QR code from their SpeedyDock app or with a check-in code.',
        isComingSoon: false,
        isNew: true,
        products: [],
        urlSlug: 'customer-kiosk',
    },
];

module.exports = addons;
